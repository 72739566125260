
import { defineComponent } from 'vue';
import ConfirmAction from '@/components/ConfirmAction.vue';
import { store } from '@/store/store';
import { STATE } from '@/constants';
import { ACTION } from '@/store/actions';
import {
    emptyMatchDef, encodeTeamDivision,
    formatDateDayOfWeek, formatTime, noTimezoneDate
} from '@/utils';
import { ReservationInterface, TimeslotInterface } from '@/services/timeslots';
import { MUTATION } from '@/store/mutations';
import { ScheduleInterface } from '@/services/series';
import { TeamInterface } from '@/services/teams';

export default defineComponent({
    name: 'TheAvailableTimeslots',
    components: { ConfirmAction },
    props: {
        match: {
            type: Object as () => ScheduleInterface,
            required: true,
            default: emptyMatchDef()
        },
        available: {
            type: Boolean,
            required: false,
            default: true
        },
        pendingOrConfirmed: {
            type: Boolean,
            required: false,
            default: true
        },
        booked: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    data() {
        return {
            availSelectedTimeslotIx: -1
        };
    },
    computed: {
        noDataText(): string {
            return 'Aucune plage horaire'
        },
        maxReservationsPerMatch(): number {
            return store.state.pjc.app.timeslots.maxReservationRequests
        },
        futureTimeslots(): (TimeslotInterface & {free: boolean, pending: boolean})[] {
            const upcomingTimeslots = [] as (TimeslotInterface & {free: boolean, pending: boolean})[];
            const lenAvail = this.availableTimeslots.length;
            const lenBooked = this.bookedTimeslots.length;
            const pendingCounter: {[matchID: string]: number} = {};
            if(this.match._id) pendingCounter[this.match._id] = 0;
            [
                ...this.availableTimeslots,
                ...this.bookedTimeslots,
                ...this.pendingTimeslots,
                ...this.confirmedTimeslots
            ].forEach((m, ix) => {
                const pending = ix >= (lenBooked + lenAvail)
                const free = ix < lenAvail
                const matchingId =
                    this.match._id !== ''?
                        !m.reservation || m.reservation.matchID == this.match._id:
                        true
                const canView = this.canViewTimeslot(m);
                    // We have two modes here. One mode where a match is defined so the data
                    // is filtered and showed only for that specific match
                    // and a mode where we show all the data for all matches
                    // In the first mode, only show available timeslots if the number of
                    // reservation for that match did not exceed the maximum number
                    // of reservations allowed.

                if (
                    canView && matchingId &&
                    (pending || noTimezoneDate(new Date(m.date)).getTime() > new Date().getTime())
                ){
                    upcomingTimeslots.push({...m, free, pending});
                    if(pending && this.match._id !== '') pendingCounter[this.match._id] ++;
                }
            });

            return upcomingTimeslots.sort((a,b) => {
                const dateDiff = new Date(a.date).getTime() - new Date(b.date).getTime();
                if (a.pending && b.pending) return dateDiff
                else if (a.pending && !b.pending) return -1
                else if (b.pending && !a.pending) return 1
                else return dateDiff

            }).filter(t => {
                return !(t.free && pendingCounter[this.match._id] >= this.maxReservationsPerMatch)
            });
        },
        availableTimeslots(): TimeslotInterface[] {
            return !this.available? []: this.timeslots.available ?? [];
        },
        bookedTimeslots(): TimeslotInterface[] {
            return !this.booked? []: this.timeslots.booked ?? [];
        },
        pendingTimeslots(): TimeslotInterface[] {
            return !this.pendingOrConfirmed? []: this.timeslots.pending ?? [];
        },
        confirmedTimeslots(): TimeslotInterface[] {
            return !this.pendingOrConfirmed?[]: this.timeslots.confirmed ?? [];
        },
        noFutureTimeslots(): boolean {
            return this.futureTimeslots.length == 0;
        },
        isAdmin() {
            return store.getters.isAdmin;
        },
        timeslots() {
            return store.state.timeslots;
        },
        timeslotsLoading(): boolean {
            return store.state.timeslotsRequestStatus.status === STATE.LOADING;
        },
        selectedAvailableTimeslot(): TimeslotInterface {
            return this.futureTimeslots[this.availSelectedTimeslotIx];
        },
        matchDivision(): {[matchID: string]: [string, string]} {
            const matchDivisionMap: {[matchID: string]: [string, string]} = {};
            store.getters.fullSchedule.forEach(m => {
                const id = m._id;
                if(m.homeDivision !== undefined && m.awayDivision !== undefined) {
                    matchDivisionMap[id] = [m.homeDivision, m.awayDivision];
                }
                else if(m.division) {
                    matchDivisionMap[id] = [m.division, m.division];
                }
                else {
                    matchDivisionMap[id] = [
                        this.match.homeTeam? this.teamInfo(this.match.homeTeam).division: '',
                        this.match.awayTeam? this.teamInfo(this.match.awayTeam).division: ''
                    ];
                }
            });
            return matchDivisionMap;
        },
        partialDivRankingProvisional(): {[division: string]: boolean} {
            const matchDivs = this.matchDivision;
            const seasonProvisional = store.getters.seasonRankingProvisional;
            const seriesProvisional = store.getters.seriesRankingProvisional;
            const rankingMap: {[division: string]: boolean} = {};
            Object.values(store.state.series).forEach(schedule => {
                schedule.forEach(m => {
                    const div = matchDivs[m._id];
                    rankingMap[div[0]] = !!(seriesProvisional[encodeTeamDivision({division: div[0], pool: ''})] ?? seasonProvisional[encodeTeamDivision({division: div[0], pool: ''})]);
                    rankingMap[div[1]] = !!(seriesProvisional[encodeTeamDivision({division: div[1], pool: ''})] ?? seasonProvisional[encodeTeamDivision({division: div[1], pool: ''})]);
                })
            })
            return rankingMap;
        },
        smallWindow(): boolean {
            const width = store.state.windowSize.width;
            return (width <= 800 && width > 600) || width <= 300;
        },
        hasValidMatch(): boolean {
            return (
                this.match._id !== '' &&
                this.match.result != undefined &&
                this.match.homeTeam != undefined && this.match.awayTeam != undefined
            )
        },
    },
    methods: {
        canViewTimeslot(timeslot: TimeslotInterface): boolean {
            const canViewTimeslot = (
                this.isAdmin ||
                !timeslot.reservation || // if free, can view
                this.isTeamCaptain(timeslot.reservation?.requestingTeam) ||
                this.isTeamCaptain(timeslot.reservation?.confirmingTeam)
            );
            return canViewTimeslot
        },
        isWaitingOnConfirmation(timeslot: TimeslotInterface): boolean {
            return !!(
                timeslot.pendingApproval &&
                (this.isAdmin && !timeslot.pendingApproval?.confirmingTeam) ||
                (this.isTeamCaptain(timeslot.reservation?.requestingTeam) && timeslot.pendingApproval?.requestingTeam) ||
                (this.isTeamCaptain(timeslot.reservation?.confirmingTeam) && timeslot.pendingApproval?.confirmingTeam)
            );
        },
        isBooked(timeslot: TimeslotInterface) {            
            return this.bookedTimeslots.find(t => t._id == timeslot._id) !== undefined
        },
        isPendingOrConfirmed(timeslot: TimeslotInterface) { 
            return this.pendingTimeslots.find(t => t._id == timeslot._id) !== undefined
        },
        hasReservation(timeslot: TimeslotInterface) {
            return (timeslot.reservation !== undefined)
        },
        isTeamCaptain(teamName?: string) {
            if(!teamName) return false;
            return store.getters.isTeamCaptain(teamName)
        },
        canSelectTimeslot(timeslot: TimeslotInterface): boolean {
            return (
                !this.isAdmin &&
                this.hasValidMatch &&
                timeslot.reservation === undefined && 
                (
                    this.isTeamCaptain(this.match.homeTeam) ||
                    this.isTeamCaptain(this.match.awayTeam)
                )
            );
        },
        removeTimeslot(id: string) {
            store.dispatch(ACTION.REMOVE_TIMESLOT, id);
        },
        requestPendingStr(timeslot: TimeslotInterface) {
            if(!timeslot.reservation) return 'Disponible';
            else if(!timeslot.pendingApproval) return 'Non-disponible';
            else if(
                timeslot.pendingApproval.confirmingTeam === true && 
                timeslot.pendingApproval.requestingTeam === true
            ) {
                return `En attente d'approbation par la ligue`;
            }
            else if (timeslot.pendingApproval.confirmingTeam === false){
                return `En attente de confirmation (${timeslot.reservation.confirmingTeam})`;
            }
            else if (timeslot.pendingApproval.requestingTeam === false){
                return `En attente de confirmation (${timeslot.reservation.requestingTeam})`;
            }

            return  `En attente de confirmation`;
        },
        formattedDate(dateStr: string): string {
            return formatDateDayOfWeek(dateStr);
        },
        formattedTime(dateStr: string): string {
            return formatTime(dateStr);
        },
        teamFromDivision(div: string, pool: string, rank: number): TeamInterface | undefined {
            return store.getters.teamFromDivisionDef(div, pool, rank);
        },
        homeTeam(match: ScheduleInterface): TeamInterface|undefined {
            if(match.homeTeam) return this.teamInfo(match.homeTeam);
            else if(match.homeDivision
                && match.homeRank !== undefined
                && match.homePool !== undefined
                // TODO Cache this, it is called way too often
                && !this.partialDivRankingProvisional[match.homeDivision]
            ) {
                return this.teamFromDivision(match.homeDivision, match.homePool, match.homeRank);
            }
            else return undefined;
        },
        awayTeam(match: ScheduleInterface): TeamInterface|undefined {
            if(match.awayTeam) return this.teamInfo(match.awayTeam);
            else if(match.awayDivision
                && match.awayRank !== undefined
                && match.awayPool !== undefined
                 // TODO Cache this, it is called way too often
                && !this.partialDivRankingProvisional[match.awayDivision]
            ) {
                return this.teamFromDivision(match.awayDivision, match.awayPool, match.awayRank);
            }
            else return undefined;
        },
        async selectAvailableTimeslot(ix: number) {
            this.availSelectedTimeslotIx = ix;
            const timeslot = this.selectedAvailableTimeslot;
            const homeTeam = this.homeTeam(this.match);
            const awayTeam = this.awayTeam(this.match);
            if(!homeTeam || !awayTeam) return;

            let [reqTeam, confTeam] = [homeTeam,awayTeam];
            const homeCaptIx = store.state.sessionInfo.perm.teams.indexOf(homeTeam.name);
            const awayCaptIx = store.state.sessionInfo.perm.teams.indexOf(awayTeam.name);
            if((homeCaptIx >= 0 || awayCaptIx >= 0) && homeCaptIx > awayCaptIx) {
                [reqTeam, confTeam] = [homeTeam,awayTeam];
            }
            else if((homeCaptIx >= 0 || awayCaptIx >= 0) && awayCaptIx > homeCaptIx) {
                [reqTeam, confTeam] = [awayTeam,homeTeam];
            }
            else if(this.isAdmin) {
                [reqTeam, confTeam] = [homeTeam,awayTeam];
            }
            else {
                console.warn('Trying to request a timeslot but you are not a captain nor an admin')
                return // you don't have the permissions
            }

            timeslot.reservation = {
                matchID: this.match._id,
                confirmingTeam: confTeam.name,
                requestingTeam: reqTeam.name
            }
            if(this.isAdmin) this.approveReservation(timeslot);
            else this.confirmTimeslotRequest(timeslot);
        },
        async confirmTimeslotRequest(timeslot: TimeslotInterface) {
            store.commit(MUTATION.CLEAR_POPUPS, undefined);

            await store.dispatch(ACTION.SEND_TIMESLOT_REQUEST, {timeslot});
            await store.dispatch(ACTION.FETCH_TIMESLOTS);
        },
        canApproveReservation(timeslot: TimeslotInterface): boolean {
            return (
                store.getters.isAdmin && (
                    (timeslot.reservation !== undefined && !this.isBooked(timeslot)) || // booked timeslot through a reservation
                    (this.match._id != '') // cancelled match is selected
                )
            );
        },
        canConfirmReservation(timeslot: TimeslotInterface): boolean {
            if (
                !timeslot.reservation ||
                !timeslot.pendingApproval ||
                timeslot.pendingApproval?.confirmingTeam
            ) {
                return false;
            }

            return (
                store.getters.isTeamCaptain(timeslot.reservation.confirmingTeam)
            );
        },
        async confirmReservation(timeslot: TimeslotInterface) {
            await store.dispatch(ACTION.CONFIRM_TIMESLOT_RESERVATION, timeslot);
            store.dispatch(ACTION.FETCH_TIMESLOTS);
        },
        async denyReservation(timeslot: TimeslotInterface) {
            // if no reservation object, do nothing
            if(timeslot.reservation) {
                await store.dispatch(ACTION.DENY_TIMESLOT_REQUEST, timeslot);
                store.dispatch(ACTION.FETCH_TIMESLOTS);
            }
        },
        // Admin only
        async approveReservation(timeslot: TimeslotInterface) {
            // Admin can set matches in a timeslot that doesn't have a reservation
            if (timeslot.reservation === undefined) { 
                timeslot.reservation = { 
                    matchID: this.match._id,
                    requestingTeam: this.match.homeTeam,
                    confirmingTeam: this.match.awayTeam,
            } as ReservationInterface
            }
            await store.dispatch(ACTION.APPROVE_TIMESLOT_RESERVATION, timeslot);
            store.dispatch(ACTION.FETCH_SCHEDULE);
        },
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        }
    },
    mounted() {
        if(store.state.timeslotsRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_TIMESLOTS);
        }
    }
});
