
import { defineComponent } from 'vue';
import TheAvailableTimeslots from '@/components/TheAvailableTimeslots.vue';
import TeamLabel from '@/components/TeamLabel.vue';
import ConfirmAction from '@/components/ConfirmAction.vue';
import Datepicker from 'vue3-datepicker';
import Checkbox from './Checkbox.vue';
import {
    availableTime, formatDateDayOfWeek, formatTime, splitScheduleByWeek,
    timezoneDate, noTimezoneDate, todayEnd, encodeTeamDivision
} from '@/utils';
import { store } from '@/store/store';
import { closeAllDialogsAndPopups, MUTATION } from '@/store/mutations';
import { EditMatchResultInput, EditSeriesResultInput, MatchInterface } from '@/services/matches';
import { FieldInterface } from '@/services/fields';
import { TeamInterface } from '@/services/teams';
import { STATE } from '@/constants';
import { ACTION } from '@/store/actions';
import { SeriesInterface, ScheduleInterface } from '@/services/series';

export default defineComponent({
    name: 'Schedule',
    components: {
        TeamLabel, Datepicker, Checkbox, ConfirmAction, TheAvailableTimeslots
    },
    props: {
        canFilter: {
            required: false,
            default: true
        },
        splitByWeek: {
            required: false,
            default: true
        },
        scrollTo: {
            type: Date,
            required: false,
            default: todayEnd()
        },
        isEditable: {
            required: false,
            default: true
        },
        filterSetName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            top: 0,
            left: 0,
            editMode: '',
            editMatchTimeSelected: '',
            editMatchFieldSelected: '',
            editMatchDateSelected: new Date(Date.now()),
            editMatchHomeSelected: '',
            editMatchAwaySelected: '',
            editMatchAwayScore: Number.NaN,
            editMatchHomeScore: Number.NaN,
            editMatchCancelled: '',
            showAvailableTimeslots: false
        }
    },
    computed: {
        smallWindow(): boolean {
            const width = store.state.windowSize.width;
            return (width <= 800 && width > 600) || width <= 300;
        },
        filteredSchedule(): ScheduleInterface[] {
            const sortedSchedules = [] as ScheduleInterface[];
            Object.values(this.splitScheduleByWeek).forEach(v => sortedSchedules.push(...v));

            return sortedSchedules;
        },
        sortedFilteredSchedule(): ScheduleInterface[] {
            const sortedSchedules = [] as ScheduleInterface[];
            Object.values(this.splitScheduleByWeek).forEach(v => sortedSchedules.push(...v));

            return sortedSchedules;
        },
        scheduleLoading(): boolean {
            return store.state.scheduleRequestStatus.status === STATE.LOADING
        },
        filteringEverything(): boolean {
            return !this.filteredSchedule.length;
        },
        filteredMatchNumber(): number[] {
            const matchMap = {} as {[key: string]: number};
            store.getters.fullSchedule.forEach((m, ix) => {
                matchMap[m._id] = ix
            });
            return this.sortedFilteredSchedule.map(m => matchMap[m._id]);
        },
        hasFilterApplied(): boolean {
            return (this.canFilter && store.getters.hasScheduleFilters(this.filterSetName) );
        },
        hasFieldFilterApplied(): boolean {
            return (this.canFilter && store.getters.hasFieldFilters(this.filterSetName) );
        },
        hasTeamFilterApplied(): boolean {
            return (this.canFilter && store.getters.hasTeamFilters(this.filterSetName) );
        },
        hasDateFilterApplied(): boolean {
            return (this.canFilter && store.getters.hasDateFilters(this.filterSetName) );
        },
        hasDivisionFilterApplied(): boolean {
            return (this.canFilter && store.getters.hasDivisionFilters(this.filterSetName));
        },
        hasSelection(): boolean {
            return store.state.selectedMatchIx !== -1;
        },
        canUpdateMatch(): boolean {
            return (store.getters.isAdmin && this.isEditable);
        },
        canCancelMatch(): boolean {
            return this.selectedMatch && 
                    (this.selectedMatch.result == undefined || 
                    (this.selectedMatch.result != undefined && this.selectedMatch.pendingApproval != undefined));
        },
        sortedTeams(): string[] {
            return store.getters.sortedTeams;
        },
        sortedFields(): string[] {
            return store.getters.sortedFields;
        },
        availableTime(): string[] {
            return availableTime(15);
        },
        teamInfo(): {[teamName: string]: TeamInterface} {
            const teamMap: {[teamName: string]: TeamInterface} = {};
            store.state.teams.forEach(t => {
                teamMap[t.name] = t
            });
            return teamMap;
        },
        matchDivision(): {[matchID: string]: [string, string]} {
            const matchDivisionMap: {[matchID: string]: [string, string]} = {};
            store.getters.fullSchedule.forEach(m => {
                const id = m._id;
                if(m.homeDivision !== undefined && m.awayDivision !== undefined) {
                    matchDivisionMap[id] = [m.homeDivision, m.awayDivision];
                }
                else if(m.division) {
                    matchDivisionMap[id] = [m.division, m.division];
                }
                else {
                    matchDivisionMap[id] = [
                        m.homeTeam? this.teamInfo[m.homeTeam].division: '',
                        m.awayTeam? this.teamInfo[m.awayTeam].division: ''
                    ];
                }
            });
            return matchDivisionMap;
        },
        partialDivRankingProvisional(): {[division: string]: boolean} {
            const matchDivs = this.matchDivision;
            const seasonProvisional = store.getters.seasonRankingProvisional;
            const seriesProvisional = store.getters.seriesRankingProvisional;
            const rankingMap: {[division: string]: boolean} = {};
            Object.values(store.state.series).forEach(schedule => {
                schedule.forEach(m => {
                    const div = matchDivs[m._id];
                    rankingMap[div[0]] = !!(seriesProvisional[encodeTeamDivision({division: div[0], pool: ''})] ?? seasonProvisional[encodeTeamDivision({division: div[0], pool: ''})]);
                    rankingMap[div[1]] = !!(seriesProvisional[encodeTeamDivision({division: div[1], pool: ''})] ?? seasonProvisional[encodeTeamDivision({division: div[1], pool: ''})]);
                })
            })
            return rankingMap;
        },
        splitScheduleByWeek(): ScheduleInterface[][] {
            return splitScheduleByWeek(store.getters.filteredSchedule(this.filterSetName));
        },
        selectedMatch(): ScheduleInterface {
            return this.sortedFilteredSchedule[store.state.selectedMatchIx];
        }
    },
    methods: {
        seriesMatchUnknown(match: ScheduleInterface): boolean {
            return (!this.homeTeam(match) || !this.awayTeam(match))
        },
        teamFromDivision(div: string, pool: string, rank: number): TeamInterface | undefined {
            return store.getters.teamFromDivisionDef(div, pool, rank);
        },
        formattedDate(dateStr?: string): string {
            if(!dateStr) return 'TBD';
            return formatDateDayOfWeek(dateStr);
        },
        formattedTime(dateStr?: string): string {
            if(!dateStr) return 'TBD';
            return formatTime(dateStr);
        },
        formattedResult(match: ScheduleInterface): {homeTeam: string, awayTeam: string} {
            const res = {homeTeam: '', awayTeam: ''};
            if(!match.result || match.result.homeTeam === undefined || match.result.awayTeam == undefined) {
                return res;
            }

            res.homeTeam = match.result.homeTeam.toString();
            res.awayTeam = match.result.awayTeam.toString();

            let hDigits = res.homeTeam.length;
            let aDigits = res.awayTeam.length;

            // To align scores properly, we add empty character to the left/right
            // so both scores have the same number of digits
            const PLACEHOLDER = '\u2007'; // empty character to align things properly
            while(hDigits > aDigits) {
                res.awayTeam = res.awayTeam + PLACEHOLDER; // add to the right
                aDigits ++;
            }
            while(aDigits > hDigits) {
                res.homeTeam = PLACEHOLDER + res.homeTeam; // add to the left
                hDigits ++;
            }

            return res;
        },
        isSelected(index: number): boolean {
            return store.state.selectedMatchIx === index;
        },
        isBeforeDate(dateTarget: Date, dateStr?: string): boolean {
            if(!dateStr) return false;
            else return (noTimezoneDate(new Date(dateStr)).getTime()) < dateTarget.getTime();
        },
        isBeforeNextMatch(match: ScheduleInterface, index: number) {
            const now = new Date();
            const mDate = match.date;
            if(!mDate) return false;
            return (
                this.isBeforeDate(now, mDate)
                && !this.isBeforeDate(now, this.sortedFilteredSchedule[index +  1]?.date)
            );
        },
        isFirstOfWeek(match: ScheduleInterface): boolean {
            if(!this.splitByWeek) return false;
            const weeks = this.splitScheduleByWeek;
            return weeks.some(w => w[0]._id === match._id)
        },
        divisionDisplay(match: ScheduleInterface): string {
            const teamDivisions = match.division?
                [match.division, match.division]:
                match.homeTeam && match.awayTeam?
                    [this.teamInfo[match.homeTeam].division, this.teamInfo[match.awayTeam].division]:
                    ['N/A', 'N/A'];
            if(teamDivisions[0] === teamDivisions[1]) return teamDivisions[0];
            else return `${teamDivisions[0]} – ${teamDivisions[1]}`;
        },
        hasResult(match: ScheduleInterface): boolean {
            return match.result !== undefined;
        },
        homeTeamResult(match: ScheduleInterface): number {
            return match.result?.homeTeam ?? 0;
        },
        awayTeamResult(match: ScheduleInterface): number {
            return match.result?.awayTeam ?? 0;
        },
        homeMatchAlt(m: ScheduleInterface) {
            return m.homePool? 
                `#${m.homeRank} (${m.homePool})`:
                `#${m.homeRank} (${m.homeDivision})`;
        },
        awayMatchAlt(m: ScheduleInterface) {
            return m.awayPool? 
                `#${m.awayRank} (${m.awayPool})`:
                `#${m.awayRank} (${m.awayDivision})`;
        },
        homeTeam(match: ScheduleInterface): TeamInterface|undefined {
            if(match.homeTeam) return this.teamInfo[match.homeTeam];
            else if(match.homeDivision
                && match.homeRank !== undefined
                && match.homePool !== undefined
                // TODO Cache this, it is called way too often
                && !this.partialDivRankingProvisional[match.homeDivision]
            ) {
                return this.teamFromDivision(match.homeDivision, match.homePool, match.homeRank);
            }
            else return undefined;
        },
        awayTeam(match: ScheduleInterface): TeamInterface|undefined {
            if(match.awayTeam) return this.teamInfo[match.awayTeam];
            else if(match.awayDivision
                && match.awayRank !== undefined
                && match.awayPool !== undefined
                 // TODO Cache this, it is called way too often
                && !this.partialDivRankingProvisional[match.awayDivision]
            ) {
                return this.teamFromDivision(match.awayDivision, match.awayPool, match.awayRank);
            }
            else return undefined;
        },
        wasCancelled(match: ScheduleInterface): boolean {
            return match.result?.cancelled !== undefined;
        },
        awaitingApproval(match: ScheduleInterface): boolean {
            return match.pendingApproval !== undefined;
        },
        selectRow(index: number) {
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, index);
        },
        fieldInfo(fieldName: string): FieldInterface {
            return store.getters.fieldInfo(fieldName);
        },
        showScheduleFilter() {
            if(!this.canFilter) return;

            closeAllDialogsAndPopups(store);
            store.commit(MUTATION.SET_ACTIVE_FILTER_SET, this.filterSetName);
            store.commit(MUTATION.SHOW_SCHEDULE_FILTERS, true);
        },
        clearScheduleFilters() {
            store.commit(MUTATION.SET_DATE_RANGE_FILTERS, {filterSet: this.filterSetName, dateRange: []});
            store.commit(MUTATION.SET_TEAM_NAMES_FILTERS, {filterSet: this.filterSetName, teamNames: []});
            store.commit(MUTATION.SET_FIELD_NAMES_FILTERS, {filterSet: this.filterSetName, fieldNames: []});
            store.commit(MUTATION.SET_DIVISION_FILTERS, {filterSet: this.filterSetName, divisions: []});
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);
        },
        matchLink(matchId: string): string {
            return `/schedule/${matchId}`
        },
        canUpdateMatchResult(match: ScheduleInterface): boolean {
            return (
                !this.seriesMatchUnknown(match)
                && (match.date? this.isBeforeDate(new Date(), match.date): false)
                && ( match.result === undefined || match.pendingApproval !== undefined )
                && (
                    (match.homeTeam? store.getters.canUpdateTeam(match.homeTeam): false)
                    || (match.awayTeam? store.getters.canUpdateTeam(match.awayTeam): false)
                )
            );
        },
        canRequestTimeslot(match: ScheduleInterface): boolean {
            if(!match.result || !match.homeTeam || !match.awayTeam) return false
            return ( this.wasCancelled(match) && (
                store.getters.canUpdateTeam(match.homeTeam) ||
                store.getters.canUpdateTeam(match.awayTeam))
            );
        },
        canApproveMatchResult(match: ScheduleInterface): boolean {
            return store.getters.canUpdateTeam(match.pendingApproval?.confirmingTeam ?? '');
        },
        removeMatch(id: string){
            store.dispatch(ACTION.REMOVE_MATCH, id);
        },
        approveMatch(id: string){
            store.dispatch(ACTION.APPROVE_MATCH_SCORES, id);
        },
        editMatch(match: ScheduleInterface) {
            this.editMode = match._id;
            this.editMatchTimeSelected = match.date? `${formatTime(match.date)}:00`: '';
            this.editMatchFieldSelected = match.field ?? '';
            this.editMatchDateSelected = match.date? new Date(match.date): new Date(Date.now());
            this.editMatchHomeSelected = match.homeTeam? match.homeTeam: '';
            this.editMatchAwaySelected = match.awayTeam? match.awayTeam: '';
            this.editMatchAwayScore = match.result?.awayTeam !== undefined?
                match.result.awayTeam: Number.NaN;
            this.editMatchHomeScore = match.result?.homeTeam !== undefined?
                match.result.homeTeam: Number.NaN;
            this.editMatchCancelled = match.result?.cancelled !== undefined?
                match.result.cancelled: '';
        },
        toggleMatchCancelled() {
            if(this.editMatchCancelled === '') {
                this.editMatchCancelled = 'Annulé';
            }
            else this.editMatchCancelled = '';
        },
        cancelEdits(match: ScheduleInterface) {
            if(this.editMode === match._id) this.editMode = '';
        },
        saveEdits(match: ScheduleInterface) {
            this.cancelEdits(match);
            store.commit(MUTATION.SET_SELECTED_MATCH_IDX, -1);

            const date = this.editMatchDateSelected;
            const [hh, mm, ss] = this.editMatchTimeSelected.split(':').map(t =>  {
                return parseInt(t)
            });
            date.setHours(hh, mm, ss, 0);

            if(this.canUpdateMatch) {
                if(match.round !== undefined){
                    const updateObj: Partial<SeriesInterface> = {
                        _id: match._id,
                        date: timezoneDate(date).toISOString(),
                        field: this.editMatchFieldSelected,
                        homeTeam: this.homeTeam(match)?.name,
                        awayTeam: this.awayTeam(match)?.name,
                        division: match.division,
                        pool: match.pool,
                        homeRank: match.homeRank,
                        awayRank: match.awayRank,
                        round: match.round,
                        homeDivision: match.homeDivision,
                        awayDivision: match.awayDivision,
                        awayPool: match.awayPool,
                        homePool: match.homePool
                    };
                    if(updateObj.homeTeam && updateObj.awayTeam && this.editMatchHomeScore >= 0 && this.editMatchAwayScore >= 0) {
                        updateObj.result = {
                            awayTeam: parseInt(this.editMatchAwayScore.toString()),
                            homeTeam: parseInt(this.editMatchHomeScore.toString())
                        }
                    }
                    if (this.editMatchCancelled !== '') {
                        updateObj.result = {cancelled: this.editMatchCancelled};
                    }
                    store.dispatch(ACTION.EDIT_SERIES, updateObj);
                }
                else {
                    const updateObj: MatchInterface = {
                        _id: match._id,
                        date: timezoneDate(date).toISOString(),
                        field: this.editMatchFieldSelected,
                        homeTeam: this.editMatchHomeSelected,
                        awayTeam: this.editMatchAwaySelected
                    };
                    if(this.editMatchHomeScore >= 0 && this.editMatchAwayScore >= 0) {
                        updateObj.result = {
                            awayTeam: parseInt(this.editMatchAwayScore.toString()),
                            homeTeam: parseInt(this.editMatchHomeScore.toString())
                        }
                    }
                    if (this.editMatchCancelled !== '') {
                        updateObj.result = {cancelled: this.editMatchCancelled};
                    }
                    store.dispatch(ACTION.EDIT_MATCH, updateObj);
                }
            }
            else if(this.canUpdateMatchResult(match)) {
                if(!(this.editMatchHomeScore >= 0) && !(this.editMatchAwayScore >= 0)) {
                    return;
                }
                if(match.round !== undefined) {
                    if(match.homeTeam && match.awayTeam){
                        const updateObj: EditSeriesResultInput = {
                            _id: match._id,
                            result: {
                                awayTeam: parseInt(this.editMatchAwayScore.toString()),
                                homeTeam: parseInt(this.editMatchHomeScore.toString())
                            },
                            awayTeam: match.awayTeam,
                            homeTeam: match.homeTeam
                        };
                        store.dispatch(ACTION.EDIT_SERIES_SCORES, updateObj);
                    }
                }
                else {
                    const updateObj: EditMatchResultInput = {
                        _id: match._id,
                        awayTeam: parseInt(this.editMatchAwayScore.toString()),
                        homeTeam: parseInt(this.editMatchHomeScore.toString())
                    };
                    store.dispatch(ACTION.EDIT_MATCH_SCORES, updateObj);
                }
            }
        },
        isEditing(match: ScheduleInterface): boolean {
            return this.editMode === ''? false: this.editMode === match._id;
        },
        scrollToDate() {
            const parent = (this.$refs.schedule as HTMLElement);
            if(!parent) {
                return;
            }

            const matches = this.sortedFilteredSchedule;
            const target = matches.findIndex((m, ix, arr) => {
                if(this.isBeforeDate(this.scrollTo, m.date) && !this.isBeforeDate(this.scrollTo, arr[ix +  1]?.date)) {
                    return ix;
                }
            });
            const scrollTo = target > 3? target - 3: 0;

            const targetRow = parent.childNodes[scrollTo] as HTMLElement;
            if(!targetRow || targetRow.scrollIntoView === undefined) return;

            targetRow.scrollIntoView();
        },
        toggleAvailableTimeslots() {
            if (this.showAvailableTimeslots) {
                this.showAvailableTimeslots = false;
            }
            else {
                this.showAvailableTimeslots = true;
            }
        },

    },
    watch: {
        sortedFilteredSchedule() {
            this.$nextTick(() => this.scrollToDate());
        },
        scrollTo() {
            this.$nextTick(() => this.scrollToDate());
        }
    },
    mounted() {
        if(store.state.scheduleRequestStatus.status === STATE.STALE) {
            store.dispatch(ACTION.FETCH_SCHEDULE);
        }
    }
});
